import { render, staticRenderFns } from "./activityCat.vue?vue&type=template&id=366a25d4&scoped=true&"
import script from "./activityCat.vue?vue&type=script&lang=js&"
export * from "./activityCat.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "366a25d4",
  null
  
)

export default component.exports